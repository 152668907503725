.LoginButton {
    padding: 5px;
    border-radius: 3px;
    background-color: #69b9ff;
    font-family: Nunito, Sans-Serif;
    display: inline-block;
    margin-right: 10px;
    margin-left: 5px;
    padding: 12px 30px;
    border-radius: 4px;
    -webkit-transition: background-color 300ms ease;
    transition: background-color 300ms ease;
    color: #fff;
    font-size: 16px;
    line-height: 21px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
}

.HollowLoginButton {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    padding: 12px 30px;
    font-size: 16px;
    border: 1px solid #fff;
    border-radius: 4px;
    -webkit-transition: background-color 300ms ease, border 300ms ease, color 300ms ease;
    transition: background-color 300ms ease, border 300ms ease, color 300ms ease;
    color: #fff;
    line-height: 21px;
    font-weight: 300;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
}

.HollowLoginText {
    cursor: pointer;
    color: white;
    size: 16px;
}

div.HollowLoginButton:hover{
    background-color: #a3a1a1;
}

a.HollowLoginText:hover{
    color: #fff;
    text-decoration: none;
}

.LoginText {
    cursor: pointer;
    color: white;
    size: 16px;
}

div.LoginButton:hover{
    background-color: #a3a1a1;
}

a.LoginText:hover{
    color: #fff;
    text-decoration: none;
}

.centerAssets{
    margin-top: 13vh;
    margin-left: 5vh;
}

.hero-subheading {
    margin-left: 5px;
    margin-bottom: 40px;
    font-family: Nunito, sans-serif;
    color: #69b9ff;
    font-size: 25px;
    line-height: 30px;
    font-weight: 150;
    text-align: left;
    letter-spacing: 3px;
    text-transform: uppercase;
    width: 500px;
}